import "assets/scss/theme.scss";
import AppLoader from "components/Common/Loader/AppLoader";
import "flatpickr/dist/themes/material_blue.css";
import { lazy, Suspense } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import ReactDOM from "react-dom";
import "react-image-crop/dist/ReactCrop.css";
import "react-image-lightbox/style.css";
import "react-loading-skeleton/dist/skeleton.css";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "serviceWorker";
import { BreadcrumbProvider } from "store/BreadcrumbContext";
import store from "./store";

// App
const App = lazy(() => import(/* webpackChunkName: 'App' */ "./App"));

const app = (
  <Provider store={store}>
    <BreadcrumbProvider>
      <BrowserRouter>
        <Suspense fallback={<AppLoader />}>
          <App />
        </Suspense>
      </BrowserRouter>
    </BreadcrumbProvider>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
