import { call, put, select, takeEvery } from "redux-saga/effects";
import { GET_SUPPORT_TICKET } from 'helpers/url_helper';
import { axiosApiAdmin } from 'helpers/api_helper';
import { FETCH_SUPPORT_TICKET } from "./actionTypes";
import {
  fetchSupportTicketSuccess,
  fetchSupportTicketError,
  setErrorMessage,
} from "store/actions"
const fetchSupportTicketData = data => {
  console.log(data.payload.id)
  axiosApiAdmin.post(GET_SUPPORT_TICKET, data.payload.id);
}
function* handleFetchSupporTicket(data) {
  console.log(data)
  try {
    const res = yield call(fetchSupportTicketData, data);
    if (res.data?.data?.data) {
      yield put(fetchSupportTicketSuccess(res.data.data.data));
    }
  } catch (err) {
    yield put(fetchSupportTicketError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

export default function* SupportPagesSaga() {
  yield takeEvery(FETCH_SUPPORT_TICKET, handleFetchSupporTicket)
}