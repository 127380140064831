import couponsSaga from "modules/Analytics/Coupons/store/saga";
import analyticsEmailsSaga from "modules/Analytics/Emails/store/saga";
import analyticsOrdersSaga from "modules/Analytics/Orders/store/saga";
import analyticsProductsSaga from "modules/Analytics/Products/store/saga";
import renewalSaga from "modules/Analytics/Renewal/store/saga";
import revenueSaga from "modules/Analytics/Revenues/store/saga";
import analyticsSubscriptionSaga from "modules/Analytics/Subscriptions/store/saga";
import analyticsUsersSaga from "modules/Analytics/Users/store/saga";
import analyticsDashboardSaga from "modules/AnalyticsDashboard/store/saga";
import ordersSaga from "modules/applications/store/orders/saga";
import ApplicationsSaga from "modules/applications/store/saga";
import applicationSubscriptionsSaga from "modules/applications/store/subscriptions/saga";
import AuthSaga from "modules/authentication/store/saga";
import CancelSubscriptionSaga from "modules/CancelSubscriptionReport/store/saga";
import cloudS3Saga from "modules/CloudS3/store/saga";
import CloudStorage from "modules/CloudStorage/store/saga";
import CouponSaga from "modules/coupons/store/saga";
import DashboardSaga from "modules/dashboard/store/saga";
import DebuggerToolSaga from "modules/debuggerTool/store/saga";
import emailLogSaga from "modules/EmailLogs/store/saga";
import failSubscriptionSaga from "modules/FailSubscription/store/saga";
import FormerSubscriptionSaga from "modules/FormerSubscription/store/saga";
import FilterSaga from "modules/Game/Filters/store/saga";
import GameSaga from "modules/Game/Games/store/saga";
import IcebreakerSaga from "modules/Game/Icebreakers/store/saga";
import ReviewSaga from "modules/Game/Review/store/saga";
import categorySaga from "modules/Habit/Categories/store/saga";
import HabitSaga from "modules/Habit/Habits/store/saga";
import IntegrationsSaga from "modules/integration/store/saga";
import LayoutSaga from "modules/layout/store/saga";
import logSaga from "modules/Logs/store/saga";
import membershipSaga from "modules/membership/membershipPlan/store/saga";
import member from "modules/membership/userMembership/store/saga";
import PermissionBuilderSaga from "modules/permissionBuilder/store/saga";
import productsSaga from "modules/product/store/saga";
import activeMembershipSaga from "modules/profile/store/activeMembership/saga";
import activeProductSaga from "modules/profile/store/activeProduct/saga";
import activeSubscriptionsSaga from "modules/profile/store/activeSubscriptions/saga";
import emailLogsSaga from "modules/profile/store/emailLogs/saga";
import loginLogsSaga from "modules/profile/store/loginLogs/saga";
import paymentMethodSaga from "modules/profile/store/paymentMethods/saga";
import profileSaga from "modules/profile/store/saga";
import supportTicketsSaga from "modules/profile/store/supportTickets/saga";
import transactionSaga from "modules/profile/store/transaction/saga";
import realtimeSaga from "modules/Realtime/store/saga";
import redirectionsSaga from "modules/redirections/store/saga";
import reportSaga from "modules/Reports/store/saga";
import subscriptionSaga from "modules/subscription/store/saga";
import SystemPagesSaga from "modules/systemPages/store/saga";
import boardSaga from "modules/tools/pages/DigitalBoard/store/saga";
import curriculumPostPageSaga from "modules/tools/store/PageEditor/saga";
import volumeSaga from "modules/tools/store/saga";
import curriculumPostSeriesSaga from "modules/tools/store/SeriesEditor/saga";
import slidrSaga from "modules/tools/store/Slidr/saga";
import volumeProductSaga from "modules/tools/store/volume-product/saga";
import userSaga from "modules/users/store/saga";
import { all, fork } from "redux-saga/effects";
import orderNoteSaga from "./orderNote/saga";

import {
  ActiveSubscriptionExportSaga,
  ActiveSubscriptionSaga,
} from "modules/ActiveSubscriptionReport/store/saga";
import DisputedTransactionsSaga from "modules/DisputedTransactions/store/saga";
import liveChatArchiveSaga from "modules/LiveChatArchives/store/saga";
import adminActivityLogs from "modules/profile/store/activeLog/saga";
import {
  subscriptionReportExportSaga,
  subscriptionReportSaga,
} from "modules/SubscriptionReport/store/saga";
import {
  SuccessSubscriptionExportSaga,
  SuccessSubscriptionSaga,
} from "modules/SuccessSubscriptionReport/store/saga";

import FreeTrialSaga from "modules/applications/components/FreeTrial/store/saga";
import GrowConSlider from "modules/applications/components/GrowCon Slidr/store/saga";
import blogSiteMapSaga from "modules/BlogSiteMap/store/saga";
import CampaignReport from "modules/Campaign Report/store/saga";
import CheckoutCampaignReport from "modules/CheckoutCampaignReport/store/saga";
import churchesSaga from "modules/Churches/store/Saga";
import {
  ExpiredSubscriptionExportSaga,
  ExpiredSubscriptionSaga,
} from "modules/ExpiredSubscription/store/saga";
import FeedbackOptionSaga from "modules/Feedback Options/store/saga";
import { FreeTrialOnBoardingList } from "modules/FreeTrialOnBoardingReport/store/saga";
import geoLocationSaga from "modules/GeoLocation/store/saga";
import GrowConSaga from "modules/GrowConReport/store/saga";
import metricReport from "modules/MetricReport/store/saga";
import {
  NewActiveSubscriptionExportSaga,
  NewActiveSubscriptionSaga,
} from "modules/NewActiveSubscriptionReport/store/saga";
import SubscriptionFeedbackReport from "modules/SubscriptionFeedbackReport/store/saga";
// import NewActiveSubscriptionExportSaga from "modules/NewActiveSubscriptionReport/store/saga";
import GrowBooksSaga from "modules/applications/components/GrowBooks/store/saga";
import ShipmentCalenderSaga from "modules/CalenderCardCancel/store/saga";
import { ModalFeedbackReportList } from "modules/ModalFeedbackReport/store/saga";
import SupportTicketsSaga from "modules/SupportTicket/store/saga"

export default function* rootSaga() {
  yield all([
    fork(ApplicationsSaga),
    fork(AuthSaga),
    fork(DashboardSaga),
    fork(CouponSaga),
    fork(LayoutSaga),
    fork(productsSaga),
    fork(userSaga),
    fork(paymentMethodSaga),
    fork(membershipSaga),
    fork(member),
    fork(subscriptionSaga),
    fork(orderNoteSaga),
    fork(volumeSaga),
    fork(profileSaga),
    fork(loginLogsSaga),
    fork(activeProductSaga),
    fork(activeMembershipSaga),
    fork(activeSubscriptionsSaga),
    fork(transactionSaga),
    fork(emailLogsSaga),
    fork(volumeProductSaga),
    fork(curriculumPostPageSaga),
    fork(curriculumPostSeriesSaga),
    fork(SystemPagesSaga),
    fork(GameSaga),
    fork(HabitSaga),
    fork(IcebreakerSaga),
    fork(FilterSaga),
    fork(PermissionBuilderSaga),
    fork(applicationSubscriptionsSaga),
    fork(ReviewSaga),
    fork(redirectionsSaga),
    fork(slidrSaga),
    fork(IntegrationsSaga),
    fork(CloudStorage),
    fork(supportTicketsSaga),
    fork(DebuggerToolSaga),
    fork(reportSaga),
    fork(ordersSaga),
    fork(logSaga),
    fork(emailLogSaga),
    fork(revenueSaga),
    fork(cloudS3Saga),
    fork(renewalSaga),
    fork(realtimeSaga),
    fork(couponsSaga),
    fork(analyticsProductsSaga),
    fork(analyticsUsersSaga),
    fork(analyticsSubscriptionSaga),
    fork(analyticsEmailsSaga),
    fork(analyticsOrdersSaga),
    fork(analyticsDashboardSaga),
    fork(categorySaga),
    fork(failSubscriptionSaga),
    fork(subscriptionReportSaga),
    fork(subscriptionReportExportSaga),
    fork(DisputedTransactionsSaga),
    fork(boardSaga),
    fork(FormerSubscriptionSaga),
    fork(adminActivityLogs),
    fork(SuccessSubscriptionSaga),
    fork(SuccessSubscriptionExportSaga),
    fork(ActiveSubscriptionSaga),
    fork(ActiveSubscriptionExportSaga),
    fork(ExpiredSubscriptionSaga),
    fork(ExpiredSubscriptionExportSaga),
    fork(liveChatArchiveSaga),
    fork(geoLocationSaga),
    fork(metricReport),
    fork(CampaignReport),
    fork(CheckoutCampaignReport),
    fork(CancelSubscriptionSaga),
    fork(FeedbackOptionSaga),
    fork(SubscriptionFeedbackReport),
    fork(GrowConSaga),
    fork(blogSiteMapSaga),
    fork(GrowConSlider),
    fork(NewActiveSubscriptionSaga),
    fork(NewActiveSubscriptionExportSaga),
    fork(FreeTrialSaga),
    fork(GrowBooksSaga),
    fork(FreeTrialOnBoardingList),
    fork(churchesSaga),
    fork(ShipmentCalenderSaga),
    fork(ModalFeedbackReportList),
    fork(SupportTicketsSaga)
  ]);
}
