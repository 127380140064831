import {
  FETCH_SUPPORT_TICKET,
  FETCH_SUPPORT_TICKET_SUCESS,
  FETCH_SUPPORT_TICKET_ERROR
} from "./actionTypes";

const initialState = {
  display_name: "",
  profile_image: "",
  assignStaff: [],
  status: "",
  date_created: "",
  date_updated: "",
  type: "",
  id: "",
  subject: "",
  description: "",
  loadingTickets: false
};

const supportTicketReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_SUPPORT_TICKET:
      return {
        ...state,
        loadingTickets: true
      };
    case FETCH_SUPPORT_TICKET_SUCESS:
      return {
        ...state,
        display_name: payload.addedByUsers.name,
        profile_image: "",
        assignStaff: payload.ba_support_tickets_assignees,
        status: payload.status,
        date_created: payload.date_created,
        date_updated: payload.date_updated,
        type: payload.type,
        id: payload.id,
        subject: payload.subject,
        description: payload.description,
        loadingTickets: false
      };
    case FETCH_SUPPORT_TICKET_ERROR:
      return {
        ...state,
        loadingTickets: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default supportTicketReducer;
