import {
  FETCH_SUPPORT_TICKET,
  FETCH_SUPPORT_TICKET_ERROR,
  FETCH_SUPPORT_TICKET_SUCESS
} from './actionTypes';

export const fetchSupportTicketData = (data) => ({
  type: FETCH_SUPPORT_TICKET, // Corrected action type
  payload: data,
});

export const fetchSupportTicketSuccess = data => ({
  type: FETCH_SUPPORT_TICKET_SUCESS,
  payload: data
})

export const fetchSupportTicketError = () => ({
  type: FETCH_SUPPORT_TICKET_ERROR
})